import { PostHog } from "posthog-js";

const CUSTOMER_ID_KEY = "tricorder-customer";
export const MONS_APP_NAME = "a2z:mons_app_name";
export const MONS_CONFIG_NAME = "a2z:mons_config_name";
export const MONS_REQUEST_ID = "a2z:request_id";
export const MONS_WEBLAB_TREATMENTS = "a2z:mons_weblab_treatment";
const UNAVAILABLE = "unavailable";
const CONTENT = "content";

export function loaded(posthog_instance: PostHog) {
  const customerId = posthog_instance.getFeatureFlag(CUSTOMER_ID_KEY, {send_event: false}) as string;

  if (!customerId) { //The customer is not logged in, we clear any Ids that might already exist from previous sessions.
    posthog_instance.reset();

  } else if (!posthog_instance._isIdentified()) { //The customer is logged in, but is not identified. We identify them.
    posthog_instance.identify(customerId);

  } else if (posthog_instance.get_distinct_id() != customerId) { //The customer is logged in, and is identified with a different Customer id than the current one. We identify them with the new id.
    posthog_instance.reset();
    posthog_instance.identify(customerId);
  }

  //The customer is logged in, and is correctly identified with the current customer ID
  posthog_instance.register_for_session({
    mons_app_name: getMetaContent(MONS_APP_NAME),
    mons_config_name: getMetaContent(MONS_CONFIG_NAME),
    mons_request_id: getMetaContent(MONS_REQUEST_ID),
    weblab_treatments: getMetaContent(MONS_WEBLAB_TREATMENTS),
  })

  // Add the posthog instance to the window object so other scripts can access it.
  window.tricorderInstance = posthog_instance;

  function getMetaContent(name: string) {
    return document.querySelector(`meta[name="${name}"]`)?.getAttribute(CONTENT) || UNAVAILABLE;
  }
}