import "./logger";
import posthog, { PostHog, PostHogConfig } from 'posthog-js'
import { loaded } from "src/userUtil";
import { metrics } from "src/metrics";

const postHogProjectKey = "phc_tGCcl9SINhy3N0zy98fdlWrc1ppQ67KJ8pZMzVZOECH";
const postHogInitOptions: Partial<PostHogConfig> = {
    api_host: '/tricorder',
    mask_all_text: true,
    loaded: loaded,
};

declare global {
    interface Window {
        isTricorderInitialized: boolean;
        tricorderInstance: PostHog;
    }
}

async function init(): Promise<void> {
    try {
        window.isTricorderInitialized = window.isTricorderInitialized || false;
        if (!window.isTricorderInitialized) {
            window.isTricorderInitialized = true;
            console.log('Initializing Tricorder analytics');
            posthog.init(postHogProjectKey, postHogInitOptions)
            metrics.publishCount('InitializationError', 0);
        }
    } catch (e) {
        console.error("Error initializing Tricorder analytics: ", e);
        metrics.publishCount('InitializationError', 1);
    }
}

(async function() {
    await init();
})();